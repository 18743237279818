import { ValidationError, setLocale } from 'yup'

import { defaultMessages } from '../validation/messages'
import { extendYup } from '../validation/extend-yup'
import { patchGlobalStorage } from '../hooks/useStorage'

expect.addEqualityTesters([
  function (a, b) {
    const aOk = a instanceof ValidationError
    const bOk = b instanceof ValidationError
    if (aOk && bOk) {
      return this.equals(a.message, b.message) && this.equals(a.errors, b.errors)
    }

    return aOk !== bOk ? false : undefined
  },
])

// Note that this is called AFTER describe blocks are evaluated but BEFORE any tests are run
beforeAll(() => {
  patchGlobalStorage()
  setLocale(defaultMessages)
  extendYup()
})
